import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, useHistory, Link } from 'react-router-dom';
import NavBar from './NavBar';
import Panel from './Panel';
import Bottom from './Bottom';
function Users(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("At the AGENTS COMPONENT ", state);
  const [tableData, setTableData] = useState([]);
  const [editableData, setEditableData] = useState({});
  const [editedData, setEditedData] = useState({});
  //console.log('tableData ',tableData);

  // Function to handle back button click
  const handleBackButton = () => {
    // Navigate to the home page when the back button is clicked
    navigate('/agents');
  };

  useEffect(() => {
    // Listen for the 'popstate' event which is triggered when the back button is clicked
    window.addEventListener('popstate', handleBackButton);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);


  const loadData = async () => {
    try {
      const request = { "agent_code": state.values.agent_code, "agent_token": state.values.agent_token, "role": state.values.role };
      console.log('Agents request ',request);
      const res = await axios.post('/agents', request);
      const data = res.data; // Correct usage
      console.log('AGENTS Response data:', data);
      setTableData(data);
    } catch (error) {
      console.error('Error fetching data from server:', error);
    }
  };
  // Filter out rows where item.role is equal to 'agent'
  const filteredTableData = tableData.filter(item => item.role === 'agent');
  //console.log('filteredTableData',filteredTableData)
  useEffect(() => {
    const fetchData = async () => {
      await loadData();
    };

    fetchData();
  }, []);
  const handleEdit = (index) => {
    setEditableData(tableData[index]);
    setEditedData(tableData[index]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  const handleSave = async () => {
    try {
      console.log('Agents editedData ',editedData);
      const res = await axios.post('/agents/save', editedData);
      console.log('Save response:', res.data);
      // Reload data after saving
      await loadData();
      // Reset the editableData state to hide the edit form
      setEditableData({});
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };
  return (
    <React.StrictMode>
      <div className="d-flex w-100 vh-100 justify-content-center align-items-start">
        <div className="d-flex w-10 vh-100 justify-content-center align-items-start"><NavBar state={state} /></div>
        <div className="bg-white p-3 rounded w-90">
          <div className="d-flex w-90 vh-90 justify-content-center align-items-center"><Panel state={state} /></div>
          <h2>Agents Manage</h2>
          <div>
            <table className="mb-2 justify-content-center align-items-start">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Created At</th>
                  <th>Status</th>
                  <th>Role</th>
                  <th>User Code</th>
                  <th>Balance</th>
                  <th>Currency</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {filteredTableData.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{new Date(item.createdAt).toLocaleString()}</td>
                    <td className='text-success'><strong>{item.status === true ? (<b>OK</b>) : (<b>NOT OK</b>)}</strong></td>
                    <td>{item.role}</td>
                    <td>{item.user_code}</td>
                    <td>{item.balance.toFixed(2)}</td>
                    <td>{item.currency}</td>
                    <td><button onClick={() => handleEdit(index)}>Edit</button></td>
                  </tr>
                ))}
              </tbody>
            </table>
            {Object.keys(editableData).length > 0 && (
              <div>
                <h3>Edit Agent</h3>
                <div>
                  <label>Name:</label>
                  <input type="text" name="name" value={editedData.name || editableData.name} onChange={handleInputChange} className="form-control rounded-0"/>
                </div>
                <div>
                  <label>Seamless mode API URL:</label>
                  <input type="text" name="url" value={editedData.url || editableData.url} onChange={handleInputChange} className="form-control rounded-0"/>
                </div>
                {/* Add other editable fields */}
                <button onClick={handleSave}>Save</button>
              </div>
            )}
          </div>
          <Bottom />
        </div>
      </div>
    </React.StrictMode>

  );
}

export default Users;

