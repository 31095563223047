function validation(values){
let error={}
const email_pattern=/^[^\s@]+@[^\s@]+\.[^\s@]+$/
const password_pattern=/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,14}$/
if(values.email===""){
    error.email="Name should not be empty"
} 
else if(!email_pattern.test(values.email)){
    error.email="Email did not match. Email should be valid"
}
else {
error.email=""
}
if(values.password===""){
    error.password="Password should not be empty"
}
else if(!password_pattern.test(values.password)){
    error.password="Password must be at least 6 characters, no more than 14 characters, and must include at least one upper case letter, one lower case letter, and one numeric digit"
}
else {
    error.password=""
}
return error;
}

export default validation;
