import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, useHistory, Link } from 'react-router-dom';
import validation from './SignupValidation';
import {v4 as uuidv4} from 'uuid';
import NavBar from './NavBar';
import Panel from './Panel';
import Bottom from './Bottom';
function SignupAgent() {
    const navigate = useNavigate();
    let { state } = useLocation();
// Function to handle back button click
const handleBackButton = () => {
    // Navigate to the home page when the back button is clicked
    navigate('/signupagent');
  };

  useEffect(() => {
    // Listen for the 'popstate' event which is triggered when the back button is clicked
    window.addEventListener('popstate', handleBackButton);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);
    const user_code=uuidv4();
    const agent_code=uuidv4();
    const agent_token=uuidv4();
    const [values,setValues]=useState({
        name:'',
        email:'',
        password:'',
        balance:2,
        user_code:user_code,
        rtp:95,
        agent_code:agent_code,
        agent_token:agent_token,
        updatedAt:'',
        createdAt:'',
        role:'agent',
        status:true,
        currency:'USD',
        token:''
    })

//fetch or axios
const [errors,setErrors]=useState({});
const handleInput=(event)=>{
        //setValues(prev=>({...prev,[event.target.name]:[event.target.value]})); as an array
        setValues(prev=>({...prev,[event.target.name]:event.target.value}));//as an object
    }
//console.log("Values to BD: ",values)    
const handleSubmit=(event)=>{
    event.preventDefault();
    setErrors(validation(values));
    const err=validation(values);
    if(err.name==="" && err.email==="" && err.password===""){

//axios to signup
axios.post('/signup',values)
.then(res=>{
    const valuesAPI={
        "user_code":res.data.user_code,
        "agent_code":res.data.agent_code,
        "agent_token":res.data.agent_token,
        "method": "user_create",
}
//console.log('valuesAPI ',valuesAPI);
//axios to signup user in to API
//alert("Signed up successfully!")
//navigate('/home', {state:{ values }});
}).catch(err=>{
    console.log(err.response.request.status)
    if(err.response.request.status===400){alert("Signed up failed! Email exists!")}
    if(err.response.request.status===500){alert("Internal Server Error!")}
});
//axios to signup_api
axios.post('/signup_api',values)
.then(res=>{
    const valuesAPI={
        "user_code":res.data.user_code,
        "agent_code":res.data.agent_code,
        "agent_token":res.data.agent_token,
        "method": "user_create",
}
//console.log('valuesAPI ',valuesAPI);
//axios to signup user in to API
alert("Signed up successfully!")
navigate('/home', {state:{ values }});
}).catch(err=>{
    console.log(err.response.request.status)
    if(err.response.request.status===400){alert("Signed up failed! Email exists!")}
    if(err.response.request.status===500){alert("Internal Server Error!")}
});
};
}
  return (
<React.StrictMode>
    <div className="d-flex w-100 vh-100 justify-content-center align-items-start">
    <div className="d-flex w-10 vh-100 justify-content-center align-items-start"><NavBar state={ state }/></div>
    <div className="bg-white p-3 rounded w-90">
    <div className="d-flex w-90 vh-90 justify-content-center align-items-center"><Panel state={ state }/></div>
    <h2>Sign Up Agent</h2>
    <form action="" onSubmit={handleSubmit}>
        <div className="mb-2">
            <label htmlFor="name"><strong>Name</strong></label>
            <input type="text" placeholder="Enter Name" name="name" className="form-control rounded-0" onChange={handleInput}/>
            {errors.name && <span className='text-danger'> {errors.name}</span>}
        </div>
        <div className="mb-2">
            <label htmlFor="email"><strong>Email</strong></label>
            <input type="email" placeholder="Enter Email" name="email" className="form-control rounded-0" onChange={handleInput}/>
            {errors.email && <span className='text-danger'> {errors.email}</span>}
        </div>        
        <div className="mb-2">
            <label htmlFor="password"><strong>Password</strong></label>
            <input type="password" placeholder="Enter Password" name="password" className="form-control rounded-0" onChange={handleInput}/>
            {errors.password && <span className='text-danger'> {errors.password}</span>}
        </div>
        <button type="sumbit" className='btn btn-success w-100 rounded-0'><strong>Sign up</strong></button>
    </form>
    <Bottom/>
    </div>
    </div>
</React.StrictMode>
  );
}

export default SignupAgent;