import SignupUser from './components/SignupUser';
import SignupAgentNew from './components/SignupAgentNew';
import SignupAgent from './components/SignupAgent';
import Login from './components/Login';
import Home from './components/Home';
import APIJili from './components/APIJili';
import API12 from './components/API12';
import Users from './components/Users';
import Pay from './components/Pay';
import NotFound from './components/NotFound';
import Transactions from './components/Transactions';
import Support from './components/Support';
import Message from './components/Message';
import Agents from './components/Agents';
import React, { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';

function App() {

  return (
    <Router>
      <Routes>
        <Route path='/' element={<SignupAgentNew/>}/>
        <Route path='/signupagentnew' element={<SignupAgentNew/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/signupuser' element={<SignupUser/>}/>
        <Route path='/signupagent' element={<SignupAgent/>}/>
        <Route path='/message' element={<Message/>}/>
        <Route path='/home' element={<Home/>}/> 
        <Route path='/apijili' element={<APIJili/>}/>
        <Route path='/api12' element={<API12/>}/>
        <Route path='/agents' element={<Agents/>}/>
        <Route path='/users' element={<Users/>}/>
        <Route path='/transactions' element={<Transactions/>}/>
        <Route path='/pay' element={<Pay/>}/>
        <Route path='/support' element={<Support/>}/>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
