import logoImage from '../images/logo4.png';
import 'bootstrap/dist/css/bootstrap.css';
import React, {useState, useEffect} from 'react';
import {Link, useLocation } from "react-router-dom";
import axios from 'axios';
function NavBar(props) {
  const agent_code = process.env.REACT_APP_SEAMLESS_CODE;
  const agent_token = process.env.REACT_APP_SEAMLESS_TOKEN;
  //const agent_code = process.env.REACT_APP_TRANSFER_CODE;
  //const agent_token = process.env.REACT_APP_TRANSFER_TOKEN;

const { state, unreadMessagesCount } = useLocation();
var [whichAPI,setWhichAPI] = useState(false);
//console.log('At the NAVBAR ',state)
var superAdmin = state.values.email;
const admin = process.env.REACT_APP_SUPERADMIN;
//console.log('superAdmin ',superAdmin)
if(superAdmin===admin) { superAdmin = true; } else { superAdmin = false; }
//checking providers
useEffect(() => {
  // Make the Axios request to get providers list
  const fetchProvidersList = async () => {
    try {
      const response = await axios.post('/api/provider_list', {
        method: 'provider_list',
        agent_code: agent_code,
        agent_token: agent_token,
        user_code: state.values.user_code,
      });

      // Assuming the response data is an array of providers
      //console.log('Home response ',response);
      //console.log('Home response.data ',response.data);
      //console.log('NavBar response.data.providers ',response.data.providers);
      // Automatically select the first provider
      if (response.data.providers.length > 0) {
        //setSelectedProvider default provider 
        setWhichAPI(true);
        console.log('whichAPI ',whichAPI)
      } else {
        setWhichAPI(false);
        console.log('whichAPI ',whichAPI)
      }
    } catch (error) {
      console.error('Error fetching providers list:', error);
    }
  };
  fetchProvidersList(); // Invoke the function when the component mounts
}, [whichAPI]); 
 return (
  <React.StrictMode>
{!superAdmin ? (
<div class="form-control d-grid gap-1 col-10 mx-auto align-items-top">
{/* NOT A superAdmin section*/}
<div className="form-control">
<img src={logoImage} alt="Company logo" className="img-fluid w-10 h-9.35"/>
</div>

<Link to="/home"  state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Home</Link>
<Link to="/agents" state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Agents</Link>
<Link to="/users" state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Users</Link>
<Link to="/transactions" state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Transactions</Link>
<Link to="/signupuser"  state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Create user</Link>
<Link to="/message"  state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Message{unreadMessagesCount > 0 && <span className="orange-spot"></span>}</Link>
<Link to="/pay"  state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Billing</Link>
<Link to="/support"  state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Help</Link>
{whichAPI === false ? (
          <Link to="/apijili" state={ state } className="btn btn-warning small-button" data-mdb-ripple-init>API</Link>
        ) : (
          <Link to="/api12" state={ state } className="btn btn-warning small-button" data-mdb-ripple-init>API</Link>
        )}
<Link to="/"  state={ state } className="btn btn-danger small-button" data-mdb-ripple-init>Logout</Link>
</div>
) : (
<div class="form-control d-grid gap-1 col-10 mx-auto align-items-top">
{/* superAdmin section*/}
<div className="form-control">
<img src={logoImage} alt="Company logo" className="img-fluid w-15 h-9.35"/>
</div>

<Link to="/home"  state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Home</Link>
<Link to="/agents" state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Agents</Link>
<Link to="/users" state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Users</Link>
<Link to="/transactions" state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Transactions</Link>
<Link to="/signupuser"  state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Create user</Link>
<Link to="/signupagent"  state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Create agent</Link>
<Link to="/message"  state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Message{unreadMessagesCount > 0 && <span className="orange-spot"></span>}</Link>
<Link to="/pay"  state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Billing</Link>
<Link to="/support"  state={ state } className="btn btn-success small-button" data-mdb-ripple-init>Help</Link>
{whichAPI === false ? (
          <Link to="/apijili" state={ state } className="btn btn-warning small-button" data-mdb-ripple-init>API</Link>
        ) : (
          <Link to="/api12" state={ state } className="btn btn-warning small-button" data-mdb-ripple-init>API</Link>
        )}
<Link to="/"  state={ state } className="btn btn-danger small-button" data-mdb-ripple-init>Logout</Link>
</div>
)}
  </React.StrictMode>
      )
  
}


export default NavBar;
