import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, useHistory, Link } from 'react-router-dom';
import NavBar from './NavBar';
import Panel from './Panel';
import Bottom from './Bottom';
function Transactions(props) {
const navigate = useNavigate();
// Function to handle back button click
const handleBackButton = () => {
  // Navigate to the home page when the back button is clicked
  navigate('/transactions');
};

useEffect(() => {
  // Listen for the 'popstate' event which is triggered when the back button is clicked
  window.addEventListener('popstate', handleBackButton);

  return () => {
    // Remove the event listener when the component is unmounted
    window.removeEventListener('popstate', handleBackButton);
  };
}, []);
  const { state } = useLocation();
  const [tableDataRounds, setTableDataRounds] = useState([]);
  const [tableDataTransactions, setTableDataTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermTransactions, setSearchTermTransactions] = useState('');
  const [dateRange, setDateRange] = useState({
    from: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
    to: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0],
  });
  const [jiliData, setJiliData] = useState('');
  
//console.log('dateRange ',dateRange)
  const loadData = async () => {
    try {
      const res = await axios.post('/transactions', {
        agent_code: state.values.agent_code,
        dateRange,
      });
      const dataRounds = res.data.rounds;
      //console.log('dataRounds ',dataRounds)
      const dataTransactions = res.data.transactions;
      //console.log('dataTransactions ',dataTransactions)
      setTableDataRounds(dataRounds);
      setTableDataTransactions(dataTransactions);
    } catch (error) {
      console.error('Error fetching data from server:', error);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      await loadData();

    };

    fetchData();
  }, [currentPage, searchTerm, searchTermTransactions, dateRange, state.values.agent_code]);

  const totalPages = Math.ceil(tableDataRounds.length / perPage);
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = Math.min(startIndex + perPage, tableDataRounds.length);
  const currentData = tableDataRounds.slice(startIndex, endIndex);

  const totalPagesTransactions = Math.ceil(tableDataTransactions.length / perPage);
  const startIndexTransactions = (currentPage - 1) * perPage;
  const endIndexTransactions = Math.min(startIndexTransactions + perPage, tableDataTransactions.length);
  const currentDataTransactions = tableDataTransactions.slice(startIndexTransactions, endIndexTransactions);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalBetAmount = currentData.reduce((total, item) => total + item.betAmount, 0).toFixed(2);
  const totalWinloseAmount = currentData.reduce((total, item) => total + item.winloseAmount, 0).toFixed(2);
  const totalPreserve = currentData.reduce((total, item) => total + item.preserve, 0).toFixed(2);
  const totalTurnover = currentData.reduce((total, item) => total + item.turnover, 0).toFixed(2);
  const GGR = (totalBetAmount - totalWinloseAmount).toFixed(2);
  const totalGrossProfit = (GGR * 0.09).toFixed(2);

  const totalBetAmountAll = tableDataRounds.reduce((total, item) => total + item.betAmount, 0).toFixed(2);
  const totalWinloseAmountAll = tableDataRounds.reduce((total, item) => total + item.winloseAmount, 0).toFixed(2);
  const totalPreserveAll = tableDataRounds.reduce((total, item) => total + item.preserve, 0).toFixed(2);
  const totalTurnoverAll = tableDataRounds.reduce((total, item) => total + item.turnover, 0).toFixed(2);
  const GGRAll = (totalBetAmountAll - totalWinloseAmountAll).toFixed(2);
  const totalGrossProfitAll = (GGRAll * 0.09).toFixed(2);

  const handleDateChange = (e, type) => {
    const { value } = e.target;
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      [type]: value,
    }));
  };
 // Calculate total values for transactions
 const totalTransactionsBetAmount = tableDataTransactions.reduce((total, item) => total + item.bet_money, 0).toFixed(2);
 const totalTransactionsWinLoseAmount = tableDataTransactions.reduce((total, item) => total + item.win_money, 0).toFixed(2);

  return (
    <React.StrictMode>
      <div className="d-flex w-100 vh-100 justify-content-center align-items-start">
        <div className="d-flex w-10 vh-100 justify-content-center align-items-start">
          <NavBar state={state} />
        </div>
        <div className="bg-white p-3 rounded w-90">
          <div className="d-flex w-90 vh-90 justify-content-center align-items-center">
            <Panel state={state} />
          </div>
          <h2>Jili Transactions</h2>
          <div>
            <div className="mt-3">
              <form className="mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button type="button" className="btn btn-primary" onClick={() => setSearchTerm('')}>
                  Clear
                </button>
                <label htmlFor="fromDate">From Date:</label>
                <input
                  type="date"
                  id="fromDate"
                  value={dateRange.from}
                  onChange={(e) => handleDateChange(e, 'from')}
                />
                <label htmlFor="toDate" className="ml-3">To Date:</label>
                <input
                  type="date"
                  id="toDate"
                  value={dateRange.to}
                  onChange={(e) => handleDateChange(e, 'to')}
                />
              </form>
            </div>
                      <nav aria-label="Page navigation">
            <ul className="pagination">
              {Array.from({ length: totalPages }).map((_, index) => (
                <li
                  className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}
                  key={index}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Created</th>
                  <th>User Code</th>
                  <th>Round</th>
                  <th>Game</th>
                  <th>Bet</th>
                  <th>Win/Lose</th>
                  <th>Preserve</th>
                  <th>Turnover</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {currentData
                  .filter((item) =>
                    Object.values(item).some(
                      (value) =>
                        typeof value === 'string' &&
                        value.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                  )
                  .map((item, index) => (
                    <tr key={startIndex + index}>
                      <td>{startIndex + index + 1}</td>
                      <td>{new Date(item.createdAt).toLocaleString()}</td>
                      <td>{item.user_code}</td>
                      <td>{item.round}</td>
                      <td>{item.game}</td>
                      <td>{item.betAmount}</td>
                      <td>{item.winloseAmount}</td>
                      <td>{item.preserve}</td>
                      <td>{item.turnover}</td>
                      <td>{item.type === 1 ? <b>Bet</b> : <b>Settle</b>}</td>
                    </tr>
                  ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{totalBetAmount}</td>
                  <td>{totalWinloseAmount}</td>
                  <td>{totalPreserve}</td>
                  <td>{totalTurnover}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <nav aria-label="Page navigation">
            <ul className="pagination">
              {Array.from({ length: totalPages }).map((_, index) => (
                <li
                  className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}
                  key={index}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
          <div>
            <p>Bet Amount: {totalBetAmount}</p>
            <p>Win/Lose Amount: {totalWinloseAmount}</p>
            <p>Preserve: {totalPreserve}</p>
            <p>Turnover: {totalTurnover}</p>
{/**        <p>Net Win (GGR) Gross Gaming Revenue: {GGR}</p>
            <p>Fee 9%: {totalGrossProfit}</p>
             */}            
          </div>

          <h2>12 Providers Transactions </h2>
        <div>
        <form className="mb-3">
            <input
              type="text"
              placeholder="Search..."
              value={searchTermTransactions}
              onChange={(e) => setSearchTermTransactions(e.target.value)}
            />
          </form>
          <table className="table table-sm">
            <thead>
              <tr>
                <th>No</th>
                <th>Created</th>
                <th>User Code</th>
                <th>Provider Code</th>
                <th>Game Code</th>
                <th>Type</th>
                <th>Bet Money</th>
                <th>Win Money</th>
                <th>Transaction ID</th>
                <th>Transaction Type</th>
              </tr>
            </thead>
            <tbody>
            {currentDataTransactions
    .filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === 'string' &&
          value.toLowerCase().includes(searchTermTransactions.toLowerCase())
      )
    )
    .map((item, index) => (
                  <tr key={startIndexTransactions + index}>
                    <td>{startIndexTransactions + index + 1}</td>
                    <td>{new Date(item.createdAt).toLocaleString()}</td>
                    <td>{item.user_code}</td>
                    <td>{item.provider_code}</td>
                    <td>{item.game_code}</td>
                    <td>{item.type}</td>
                    <td>{item.bet_money}</td>
                    <td>{item.win_money}</td>
                    <td>{item.txn_id}</td>
                    <td>{item.txn_type}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <nav aria-label="Page navigation">
              <ul className="pagination">
                {Array.from({ length: totalPagesTransactions }).map((_, index) => (
                  <li
                    className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          <p>Total Bet Amount: {totalTransactionsBetAmount}</p>
          <p>Total Win/Lose Amount: {totalTransactionsWinLoseAmount}</p>
 
          <Bottom/>
        </div>
      </div>
    </React.StrictMode>
  );
}

export default Transactions;
