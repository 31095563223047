import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import notFound from '../images/4041.jpg';
import NavBar from './NavBar';
import Panel from './Panel';
import Bottom from './Bottom';

function NotFound() {
  //const location = useLocation(); // Use useLocation hook to get the location object
  const navigate = useNavigate();

  // Function to handle back button click
  const handleBackButton = () => {
    // Navigate to the home page when the back button is clicked
    navigate('/login');
  };

  useEffect(() => {
    // Listen for the 'popstate' event which is triggered when the back button is clicked
    window.addEventListener('popstate', handleBackButton);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <React.StrictMode>
      <div className="d-flex w-100 vh-50 justify-content-center align-items-start">
        <div className="d-grid w-100 vh-50 p-2 rounded bg-white">
          <img src={notFound} alt="404" className="img-fluid" style={{ maxHeight: '100%', maxWidth: '100%' }} />
          <Link to="/login" className='btn btn-default border w-100 vh-10 rounded-0 bg-light text-decoration-none'>Navigate Login</Link>
          <Bottom />
        </div>
      </div>
    </React.StrictMode>
  );
}

export default NotFound;
