function validation(values){
    let error={}
    const email_pattern=/^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const password_pattern=/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,14}$/
    /*
    if(95<values.rtp || 50>values.rtp){
        error.rtp='Please enter (50 ~ 95)'
    } else
    if(0>(values.parentBalance-values.balance)){
        error.balance=`${values.parentBalance}$ is not enough to register new account with ${values.balance}$ balance`
    } else
    */
    if(values.name===""){
        error.name="Name should not be empty"
    } 
    else {
    error.name=""
    }
    if(values.email===""){
        error.email="Email should not be empty"
    } 
    else if(!email_pattern.test(values.email)){
        error.email="Email did not match. Email should be valid and contain @"
    }
    else {
    error.email=""
    }
    if(values.password===""){
        error.password="Password should not be empty"
    }
    else if(!password_pattern.test(values.password)){
        error.password="Password must be at least 6 characters, no more than 14 characters, and must include at least one upper case letter, one lower case letter, and one numeric digit"
    }
    else {
        error.password=""
    }
    
    return error;
    }
    
    export default validation;
    