// API.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NavBar from './NavBar';
import Panel from './Panel';
import Bottom from './Bottom';
import axios from 'axios';

function API12(props) {
  const navigate = useNavigate();
  // Function to handle back button click
  // Function to handle back button click
  const handleBackButton = () => {
    // Navigate to the home page when the back button is clicked
    navigate('/api12');
  };

  useEffect(() => {
    // Listen for the 'popstate' event which is triggered when the back button is clicked
    window.addEventListener('popstate', handleBackButton);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);
  const { state } = useLocation();
  //console.log('At the API 12', state);
  const [isLoadingPost, setIsLoadingPost] = useState(true);
  const [isLoadingGet, setIsLoadingGet] = useState(true);
  //POST
  
  useEffect(() => {
    axios
      .post('/json2yaml12', state)
      .then(res => {
        console.log('res POST',res)
      })
      .catch(error => {
        console.error('Error message from POST:', error);
        // Handle the error appropriately (e.g., show an error message to the user)
      })
      .finally(() => {
        // Simulate a delay of 2 seconds
        const delay = 2000;
        const timer = setTimeout(() => {
          setIsLoadingPost(false);
        }, delay);

        return () => clearTimeout(timer);
      })
  }, [state]);

  //GET
  
  useEffect(() => {
    axios
      .get('/json2yaml12', { param: state })
      .then(res => {
        console.log('res GET', res);
      })
      .catch(error => {
        console.error('Error message from GET:', error);
        // Handle the error appropriately (e.g., show an error message to the user)
      })
      .finally(() => {
        // Simulate a delay of 2 seconds
        const delay = 2000;
        const timer = setTimeout(() => {
          setIsLoadingGet(false);
        }, delay);

        return () => clearTimeout(timer);
      });
  }, [state]);
  
  return (
    <React.StrictMode>
      <div className="d-flex w-100 vh-100 justify-content-center align-items-start">
        <div className="d-flex w-10 vh-100 justify-content-center align-items-start"><NavBar state={state} /></div>
        <div className="d-grid w-90 vh-1000 p-2 rounded bg-white">
          <div className="d-flex w-100 vh-10 justify-content-center align-items-start"><Panel state={state} /></div>
          {isLoadingPost || isLoadingGet ?
            (<div className="mb-2 justify-content-center align-items-center"><strong>API documentation is Loading ...</strong></div>)
            :
            (<div className="mb-2 justify-content-center align-items-center">
              <div className="mb-2 justify-content-center align-items-center"><strong>API credentails below are confidential</strong> </div>
              <div className="mb-2 justify-content-center align-items-center">
                <span className="mb-2">agent_code </span><span className="text-white bg-success">{state.values.agent_code}</span>
              </div>
              <div className="mb-2 justify-content-center align-items-center">
                <span className="mb-2">agent_token </span><span className="text-white bg-success">{state.values.agent_token}</span>
              </div>
              <iframe
                title="API Documentation"
                src={`https://api.highconnect.net/apidocs12`}
                //src={`https://api.highconnect.net/apidocs12?timestamp=${new Date().getTime()}`}
                //src={`http://localhost:8081/apidocs12?timestamp=${new Date().getTime()}`}
                //src={`http://localhost:8081/apidocs12`}
                //src="/apidocs12"
                //src="http://localhost:8081/apidocs12?user_code=${state.values.user_code}"
                style={{
                  border: 'none',
                  overflow: 'auto',
                  display: 'block',
                  width: '100%',
                  height: '1000px'
                }}
              />
            </div>
            )}
          <Bottom />
        </div>
      </div>
    </React.StrictMode>
  );
}

export default API12;
