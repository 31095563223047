import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import validation from './LoginValidation';
import axios from 'axios';
// Set a default timeout for all Axios requests
function Login() {
    const [errors,setErrors]=useState({});
    const navigate = useNavigate();
    let { state } = useLocation();
// Function to handle back button click
const handleBackButton = () => {
    // Navigate to the home page when the back button is clicked
    navigate('/login');
  };

  useEffect(() => {
    // Listen for the 'popstate' event which is triggered when the back button is clicked
    window.addEventListener('popstate', handleBackButton);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);
    const [values, setValues]=useState({
        id:'',
        name:'',
        email:'',
        password:'',
        balance:'',
        user_code:'',
        rtp:'',
        agent_code:'',
        createdAt:'',
        updatedAt:'',
        agent_token:'',
        status:'',
        role:'',
        currency:'',
        token:'',
        lang:'',

    });
   // console.log("Login data: ",values);
    const handleInput=(event)=>{
        //setValues(prev=>({...prev,[event.target.name]:[event.target.value]})); //as an object of arrays 
        setValues(prev=>({...prev,[event.target.name]:event.target.value})); //as an objects
    }
    const [buttonClicked, setButtonClicked] = useState(false);
    const handleSubmit = (event) => {
        event.preventDefault();
        setButtonClicked(true);
    };

    useEffect(() => {
        // Validate only when the button is clicked
        if (buttonClicked) {
            const validationErrors = validation(values);
            setErrors(validationErrors);

            if (validationErrors.email === '' && validationErrors.password === '') {
                axios
                    .post('/login', values)
                    .then((res) => {
                        console.log("Login res ",res)
                        if (res.data.length !== 0) {
                            const values = res.data;
                            navigate('/home', { state: { values } });
                        } else {
                            alert('No record existed');
                        }
                    })
                    .catch((err) => {
                        console.log(err.response.request.status);
                        if (err.response.request.status === 401) {
                            alert('Invalid credentials');
                        } else if (err.response.request.status === 404) {
                            alert('User not found');
                        } else if (err.response.request.status === 500) {
                            alert('Internal Server Error');
                        }
                    });
            }
            // Reset the buttonClicked flag after handling submit
            setButtonClicked(false);
        }
    }, [values, buttonClicked, navigate]);
    
    
    return (
<React.StrictMode>
<div className="d-flex w-100 vh-100 justify-content-center align-items-start">
    <div className="bg-white p-3 rounded w-90">
    <h2>Log in</h2>
    <form action="" onSubmit={handleSubmit} >
    <div className="mb-2">
<label htmlFor="email">Email</label>
<input name='email' onChange={handleInput} type="email" placeholder='Enter Email' className="form-control rounded-0"/>
{errors.email && <span className='text-danger'> {errors.email}</span>}
    </div>
    <div className="mb-2">
<label htmlFor="password">Password</label>
<input name='password' onChange={handleInput} type="password" placeholder='Enter Password' className="form-control rounded-0"/>
{errors.password && <span className='text-danger'> {errors.password}</span>}
    </div>
    <button type="submit" className='btn btn-success w-100 rounded-0'><strong>Log in</strong></button>
    </form>
    <p>You are agree to our terms and policies</p>
    <Link to="/signupagentnew" className='btn btn-default border w-100 rounded-0 bg-light text-decoration-none'>Sign up</Link>
    </div>    
    </div>
    </React.StrictMode>
  );
}

export default Login;
