import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useHistory, Link } from 'react-router-dom';
import NavBar from './NavBar';
import Panel from './Panel';
import Bottom from './Bottom';
function Support(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
// Function to handle back button click
const handleBackButton = () => {
  // Navigate to the home page when the back button is clicked
  navigate('/support');
};

useEffect(() => {
  // Listen for the 'popstate' event which is triggered when the back button is clicked
  window.addEventListener('popstate', handleBackButton);

  return () => {
    // Remove the event listener when the component is unmounted
    window.removeEventListener('popstate', handleBackButton);
  };
}, []);
    return (
    <React.StrictMode>
      <div className="d-flex w-100 vh-100 justify-content-center align-items-start">
        <div className="d-flex w-10 vh-100 justify-content-center align-items-start">
          <NavBar state={state} />
        </div>
        <div className="bg-white p-3 rounded w-90">
          <div className="d-flex w-90 vh-90 justify-content-center align-items-center">
            <Panel state={state} />
          </div>
          <h2>Support</h2>
          <div className="gap-3 d-flex justify-content-around mt-4 p-3">
          <div className="mb-2">
                  <label for="email"><strong>Telegram: </strong><a href="https://t.me/sr_alx" target="_blank">Sr Alex</a></label>
                </div>
                <div className="mb-2">
                  <label for="email"><strong>Email: </strong>admin@highconnect.net</label>
                </div>
                <div className="mb-2">
                  <label for="telegram"><strong>Channel: </strong><a href="https://t.me/api_casino777" target="_blank">IGAMING WHITELABLE API</a></label>
                </div>
          </div>
          <Bottom/>
        </div>
      </div>
    </React.StrictMode>
  );
}

export default Support;
