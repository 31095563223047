import { useLocation, useNavigate} from 'react-router-dom';
import profileImage1 from '../images/1.jpg';
import profileImage2 from '../images/2.jpg';
import profileImage3 from '../images/3.jpg';
import profileImage4 from '../images/4.jpg';
import profileImage5 from '../images/5.jpg';
import profileImage6 from '../images/6.jpg';
import profileImage7 from '../images/7.jpg';
import profileImage8 from '../images/8.jpg';
import profileImage9 from '../images/9.jpg';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
function Panel({ updateValues }) {
  const { state } = useLocation();
  const navigate = useNavigate(); // Use useHistory hook
  
  const [errorState, setErrorState] = useState(null);
  const [updatedState, setUpdatedState] = useState([]);
  const [timerFlag, setTimerFlag] = useState(false);

  // List of image filenames in the src/images folder
  const imageList = [
    profileImage1,
    profileImage2,
    profileImage3,
    profileImage4,
    profileImage5,
    profileImage6,
    profileImage7,
    profileImage8,
    profileImage9,
  ];
  // Get a random index to select a random image
  const randomIndex = Math.floor(Math.random() * imageList.length);
  //console.log('At the PANEL ',state)
  useEffect(() => {
    // Check if there is state in the location object
    if (navigate.state && navigate.state.error) {
      // If error state is found, update the error state in the component
      setErrorState(navigate.state);
    }
  }, [navigate.state]);
  //console.log('errorState ',errorState)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post('/login', state.values);
        //console.log('login Panel', res)
        if (res.data.length !== 0) {
          const values = res.data;
          setUpdatedState(values);
          // Call the callback function to update the state in the parent component
          updateValues(values);
          // console.log('login values at Panel', values);
          // console.log('state values at Panel', state);
        } else {
          alert('No record existed');
        }
      } catch (err) {
        //console.log(err.response?.request?.status);
        if(err.response?.request?.status){
        if (err.response?.request?.status === 401) {
          alert('Invalid credentials');
        } else if (err.response?.request?.status === 404) {
          alert('User not found');
        } else if (err.response?.request?.status === 500) {
          alert('Internal Server Error');
        }
      } else {console.log('Error at Panel: ',err)}
      }
    };
    fetchData();
    // Set up a timer to refetch data every 5 seconds
    const timer = setInterval(() => {
      setTimerFlag((prevFlag) => !prevFlag);
    }, 6000);

    // Clear the timer on component unmount
    return () => clearInterval(timer);
  }, [timerFlag]);
  return (
    <div className="form-control d-flex w-100 vh-20 justify-content-around align-items-start">
      <div className="md-6 w-33 vh-20 d-flex align-items-center">
        <span className="rounded-0"><strong>Balance: </strong></span>
        <span className="rounded-0">{updatedState.balance?.toFixed(2)}</span>
      </div>
      <div className="md-6 w-33 vh-20 d-flex align-items-center">
        <span className="rounded-0"><strong>Role: </strong></span>
        <span className="rounded-0">{updatedState.role}</span>
      </div>
      <div className="d-flex w-34 vh-20 justify-content-center align-items-center">
        {randomIndex !== -1 && (
          <img src={imageList[randomIndex]} alt="Profile image" style={{ width: '50px', height: '50px' }} />
        )}
      </div>
    </div>

  );
}

export default Panel;
