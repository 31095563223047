import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import NavBar from './NavBar';
import Panel from './Panel';
import Bottom from './Bottom';
import axios from 'axios';
import { format } from 'date-fns';
function Home(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [providersList, setProvidersList] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [gamesList, setGamesList] = useState([]);
  const [launchUrl, setLaunchUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [updatedState, setUpdatedState] = useState([]);

  const agent_code = process.env.REACT_APP_SEAMLESS_CODE;
  const agent_token = process.env.REACT_APP_SEAMLESS_TOKEN;
  //const agent_code = process.env.REACT_APP_TRANSFER_CODE;
  //const agent_token = process.env.REACT_APP_TRANSFER_TOKEN;

  //console.log('agent_token',agent_token)
  // Function to handle back button click
  useEffect(() => {
    const handleBackButton = () => {
      // Navigate to the home page when the back button is clicked
      navigate('/home');
    };
    // Listen for the 'popstate' event which is triggered when the back button is clicked
    window.addEventListener('popstate', handleBackButton);
    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);
  const logged = true;
  // Callback function to update the state with values from Panel.js
  const updateValues = (values) => {
    setUpdatedState(values);
  };
  console.log('state at home ', state)
  //console.log('updatedState at home ',updatedState) 
  //10PROVIDER
  useEffect(() => {
    // Make the Axios request to get providers list
    const fetchProvidersList = async () => {
      try {
        const response = await axios.post('/api/provider_list', {
          method: 'provider_list',
          agent_code: agent_code,
          agent_token: agent_token,
          user_code: state.values.user_code,
        });
        // Assuming the response data is an array of providers
        //console.log('Home response ',response);
        //console.log('Home response.data ',response.data);
        console.log('Home response.data.providers ', response.data.providers);
        setProvidersList(response.data.providers);
        // Automatically select the first provider
        if (response.data.providers.length > 0) {
          //setSelectedProvider default provider 
          setSelectedProvider(response.data.providers[0].code);
        }
      } catch (error) {
        console.error('Error fetching providers list:', error);
      }
    };
    fetchProvidersList(); // Invoke the function when the component mounts
  }, []); // The empty dependency array ensures that this effect runs only once
  useEffect(() => {
    // Make the Axios request to get game list based on the selected provider
    const fetchGamesList = async () => {
      try {
        const response = await axios.post('/api/game_list', {
          method: 'game_list',
          agent_code: agent_code,
          agent_token: agent_token,
          provider_code: selectedProvider,
          user_code: state.values.user_code,
        });
        //console.log('game_list fiver ',response.data.games)
        // Assuming the response data is an array of games
        setGamesList(response.data.games);
      } catch (error) {
        console.error('Error fetching games list:', error);
      }
    };
    if (selectedProvider) {
      fetchGamesList(); // Invoke the function when the selected provider changes
    }
  }, [selectedProvider]);


  const launchGame = async (gameCode) => {
    try {
      //for transfer api but better switch to seamless api
      //or in transactions verify by get_game_log
      /*
      const transfer = await axios.post('/api',{
        method: 'user_deposit',
        agent_code: agent_code,
        agent_token: agent_token,
        user_code: state.values.user_code,
        amount: 1,
      });
      */
      const response = await axios.post('/api/game_launch', {
        method: 'game_launch',
        agent_code: agent_code,
        agent_token: agent_token,
        user_code: state.values.user_code,
        provider_code: selectedProvider,
        game_code: gameCode,
        lang: 'en',
      });
      //console.log('response.data.launch_url ',response.data.launch_url)
      //const newWindow = window.open(response.data.launch_url, '_blank');
      const newWindow = window.open('', '_blank');
      if (newWindow) {
        const iframe = document.createElement('iframe');
        iframe.src = response.data.launch_url;
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        newWindow.document.body.appendChild(iframe);

        //newWindow.focus();
      }
    } catch (error) {
      console.error('Error launching game:', error);
    }
  };

  const renderModalContent = () => {
    if (!launchUrl) {
      return <p>Loading...</p>;
    }
  }

  //Top News
  const currentDate = new Date();
  //console.log('state.values.createdAt', state.values.createdAt.split('T')[0])
  //console.log('currentDate.toISOString()', currentDate.toISOString().split('T')[0])
  if (updatedState.createdAt && updatedState.createdAt.split('T')[0] === currentDate.toISOString().split('T')[0]) {
    var notification =
      <div>
        <div className="mb-2"><strong>Thank you for signing up with us!</strong></div>
        {state.values.role === 'agent' ? (<div className="mb-2">Welcome {state.values.name}!</div>
      ) : (<div>Welcome {state.values.name}!</div>)}
        {/*<div className="mb-2">The prohibited areas: Taiwan, Macau, China, Hong Kong, Australia, Canada, Singapore.</div>*/}
        <div className="mb-2">Contact us <Link to="/support" state={state} style={{ color: 'white' }}>contact</Link></div>
        <div className="mb-2">Play more <Link to="/pay" state={state} style={{ color: 'white' }}>$$$</Link></div>
      </div>
  } else {
    var notification =
      <div>
        {state.values.role === 'user' ? (<div className="mb-2">Welcome {state.values.name}!</div>) : (<div>Welcome {state.values.name}!</div>)}
        {/*<div className="mb-2">The prohibited areas: Taiwan, Macau, China, Hong Kong, Australia, Canada, Singapore.</div>*/}
        <div className="mb-2">Postpay and Integration <Link to="/support" state={state} style={{ color: 'white' }}>contact</Link></div>
        <div className="mb-2">12+ more Provider <Link to="/pay" state={state} style={{ color: 'white' }}>open</Link></div>
      </div>
  }

  //JILI
  const [selectedCategory, setSelectedCategory] = useState('8');
  const [filteredJiliGames, setFilteredJiliGames] = useState([]);
  const [jiliGameCategoryId, setjiliGameCategoryId] = useState([]);
  // Importing images dynamically
  const importImagesById = (context) => {
    let imagesById = {};
    context.keys().forEach((key) => {
      const matches = key.match(/\d+/g); // Extracting numeric ID(s) from the filename
      if (matches) {
        matches.forEach((imageId) => {
          imagesById[imageId] = context(key);
        });
      }
    });
    return imagesById;
  };
  var imagesById = importImagesById(require.context('../images/128x128', false, /\.(png|jpg)$/));
  const getBetRecordSummary = async () => {
    try {
      const EndTime = new Date();
      const formattedEndTime = format(EndTime, "yyyy-MM-dd'T'HH:mm:ss");
      var StartTime = new Date(EndTime - 72 * 60 * 60 * 1000);
      var formattedStartTime = format(StartTime, "yyyy-MM-dd'T'HH:mm:ss");
      const response = await axios.get(`/callbackurl/getbetrecordsummary?StartTime=${formattedStartTime}&EndTime=${formattedEndTime}`);
    } catch (error) {
      console.error('Error fetching getBetRecordSummary list:', error);
    }
  }

  const getMemberInfo = async () => {
    try {
      const response = await axios.get(`/callbackurl/getmemberinfo?user_code=${state.values.user_code}`);
      //console.log('response GetMemberInfo:', response);
    } catch (error) {
      console.error('Error fetching GetMemberInfo list:', error);
    }
  }

  const fetchGamesList = async () => {
    try {
      const response = await axios.post(`/callbackurl/getgamelist?user_code=${state.values.user_code}`);
      const jiliGameCategoryId = [
        { "GameCategoryId": 1, "Category": "Slot" },
        { "GameCategoryId": 2, "Category": "Poker" },
        { "GameCategoryId": 3, "Category": "Lobby" },
        { "GameCategoryId": 5, "Category": "Fishing" },
        { "GameCategoryId": 8, "Category": "Casino (including bingo)" },
      ];
      //console.log('jiliGameCategoryId ', jiliGameCategoryId)
      //console.log('response ', response)
      setjiliGameCategoryId(jiliGameCategoryId);

      const filteredGamesData = response.data.Data.filter(game => selectedCategory.includes(game.GameCategoryId));
      setFilteredJiliGames(filteredGamesData);
    } catch (error) {
      console.error('Error fetching gamesJili list:', error);
    }
  };
  const getCategoryName = (categoryId) => {
    const category = jiliGameCategoryId.find((cat) => cat.GameCategoryId === categoryId);
    return category ? category.Category : 'Unknown';
  };
  useEffect(() => {
    fetchGamesList();
    getMemberInfo();
    //getBetRecordSummary(); The frequency of same request is too high, Please try again later
  }, [state, selectedCategory]);

  const openJiliContentInNewWindow = async (gameId) => {
    try {
      const lang = "en-US";
      const currency = "USD";
      const loginResponse = await axios.get(`/callbackurl/?GameId=${gameId}&user_code=${state.values.user_code}&Lang=${lang}&currency=${currency}&balance=${state.values.balance}`);
      // Process the login response as needed
      //console.log('state.values.user_code ',state.values.user_code);
      //console.log('state.values.balance ',state.values.balance);
      //console.log('loginResponse:', loginResponse);
      //console.log('loginResponse.data.Data:', loginResponse.data.Data);
      //const newWindow = window.open(loginResponse.data.Data, '_blank');
      const newWindow = window.open('', '_blank');
      if (newWindow) {
        // Focus on the new window (optional)
        const iframe = document.createElement('iframe');
        iframe.src = loginResponse.data.Data;
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        newWindow.document.body.appendChild(iframe);
        //newWindow.focus();

        //const response = await axios.get(`/callbackurl/getmusthitby?GameId=${gameId}`);
        //console.log('response getMustHitBy:', response);

      }
    } catch (error) {
      console.error('Error fetching login data:', error);
    }
  }

  return (
    <React.StrictMode>
      <div className="d-flex w-100 vh-100 justify-content-center align-items-start">
        <div className="d-flex w-10 vh-100 justify-content-center align-items-start"><NavBar state={state} /></div>
        <div className="d-grid w-90 vh-1000 p-2 rounded bg-white">
          <div className="d-flex w-100 vh-10 justify-content-center align-items-start"><Panel state={state} updateValues={updateValues} /></div>
          <h2>Dashboard</h2>
          <div className="row">
            <div className="form-control d-flex w-100 vh-20 justify-content-around align-items-start">
              <div className="mb-2">
                <label htmlFor="currency"><strong>Currency:</strong></label>
                <span>{state.values.currency}</span>
              </div>
              <div className="mb-2">
                <label htmlFor="fee"><strong>API Fee:</strong></label>
                <span>{state.values.comission}%</span>
              </div>
            </div>

            <div className="form-control d-flex w-100 vh-20 justify-content-around align-items-start">
              <div className="mb-2">
                <label htmlFor="currency"><strong>Currency Rate (USD:{state.values.currency}):</strong></label>
                <span>1:{state.values.currency_rate}</span>
              </div>
              <div className="mb-2">
                <label htmlFor="calculation"><strong>Calculation:</strong></label>
                <span>$100*{state.values.currency_rate}/({state.values.comission}/100)</span>
              </div>
            </div>
          </div>

          <div className="form-control d-flex w-100 vh-20 justify-content-around align-items-start bg-success text-white">
            <div className="mb-2">
              <label htmlFor="notification"><strong>Free spins added!</strong></label>
              <br></br>
              <span>↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓</span>
            </div>
            <div className="mb-2">
              <label htmlFor="notification"><strong>{notification}</strong></label>
              <span></span>
            </div>
          </div>

          <div className="mb-2 justify-content-center align-items-start">
            <label htmlFor="providers"><strong>Featured JILI provider </strong></label>
            <select
              id="providers"
              name="providers"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="" disabled>Select category</option>
              {jiliGameCategoryId.map((category) => (
                <option key={category.GameCategoryId} value={category.GameCategoryId}>
                  {category.Category}
                </option>
              ))}
            </select>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {filteredJiliGames.map((game) => (
              <div className="mb-2"
                key={game.GameId}
                style={{
                  marginBottom: '1px',
                  flex: '0 0 10%',
                  boxSizing: 'border-box',
                  padding: '0 1px',
                  cursor: 'pointer',
                }}
              >
                <div className="mb-2">
                  <div className="mb-2 justify-content-center align-items-start">
                    <img onClick={() => openJiliContentInNewWindow(game.GameId)} src={imagesById[game.GameId]} alt={game.name['en-US'] || game.name['zh-CN'] || game.name['zh-TW']} />
                  </div>
                  {/*
                  <div className="mb-2">
                    <strong>Game name:</strong><br />
                    English:{game.name['en-US']}<br />
                    Chinese:{game.name['zh-CN']}<br />
                    Taiwan:{game.name['zh-TW']}
                  </div>
                  <div className="mb-2">
                    <strong>ID:</strong> {game.GameId}
                  </div>
                  <div className="mb-2">
                    <strong>Category:</strong> {getCategoryName(game.GameCategoryId)}
                  </div>
                  <div className="mb-2">
                    <strong>Jackpot:</strong> {game.JP ? 'Yes' : 'No'}
                  </div>
                  <div className="mb-2">
                    <strong>Free Spin:</strong> {game.Freespin ? 'Yes' : 'No'}
                  </div>
              */}
                </div>
              </div>
            ))}
          </div>
          {/*12 Providers*/}

          <div className="mb-2 justify-content-center align-items-start">
            <label htmlFor="providers"><strong>12 more providers</strong></label>
            <select
              id="providers"
              name="providers"
              value={selectedProvider}
              onChange={(e) => setSelectedProvider(e.target.value)}
            >
              <option value="" disabled>Select a provider</option>
              {providersList && providersList.map((provider) => (
                <option key={provider.id} value={provider.code}>
                  {provider.name}
                </option>
              ))}
            </select>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {gamesList.map((game) => (
              <div
                key={game.id}
                style={{
                  marginBottom: '1px',
                  flex: '0 0 10%',
                  boxSizing: 'border-box',
                  padding: '0 1px',
                  cursor: 'pointer',
                }}
                onClick={() => launchGame(game.game_code)}
              >
                <div style={{ width: '100px', overflow: 'hidden' }}>
                  <img src={game.banner} alt={game.game_name} style={{ width: '100%', height: '100%', marginBottom: '1px' }} />
                </div>
                {/*<span>{ showModal ? ( <a href={launchUrl} title="Launch game" target='blank'>{game.game_name}</a> ) : renderModalContent() }</span>*/}
              </div>
            ))}
          </div>

          <Bottom />
        </div>
      </div>

    </React.StrictMode>

  );
}
export default Home;
