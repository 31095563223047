import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate, useLocation, useHistory, Link } from 'react-router-dom';
import NavBar from './NavBar';
import Panel from './Panel';
import Bottom from './Bottom';
function Users(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
// Function to handle back button click
const handleBackButton = () => {
  // Navigate to the home page when the back button is clicked
  navigate('/users');
};

useEffect(() => {
  // Listen for the 'popstate' event which is triggered when the back button is clicked
  window.addEventListener('popstate', handleBackButton);

  return () => {
    // Remove the event listener when the component is unmounted
    window.removeEventListener('popstate', handleBackButton);
  };
}, []);

  //console.log("At the USERS COMPONENT ",state); 
  const [tableData, setTableData] = useState([]);
  //console.log('tableData ',tableData);
 
const loadData = async () => {
  try {
    const res = await axios.post('/users', { "agent_code": state.values.agent_code });
    const data = res.data; // Correct usage
    //console.log('Response data:', data);
    setTableData(data);
  } catch (error) {
    console.error('Error fetching data from server:', error);
  }
};
 // Filter out rows where item.role is equal to 'agent'
 const filteredTableData = tableData.filter(item => item.role !== 'agent');
  //console.log('filteredTableData',filteredTableData)
useEffect(() => {
    const fetchData = async () => {
      await loadData();
    };
  
    fetchData();
  }, []);  
  return (
    <React.StrictMode>
    <div className="d-flex w-100 vh-100 justify-content-center align-items-start">
    <div className="d-flex w-10 vh-100 justify-content-center align-items-start"><NavBar state={ state }/></div>
    <div className="bg-white p-3 rounded w-90">
    <div className="d-flex w-90 vh-90 justify-content-center align-items-center"><Panel state={ state }/></div>
        <h2>User Manage</h2>
        <div>
    <table className="mb-2 justify-content-center align-items-start">
      <thead>
        <tr>
          <th>No</th>
          <th>Name</th>
          <th>Created At</th>
          <th>Status</th>
          <th>Role</th>
          <th>User Code</th>
          <th>Balance</th>
          <th>Currency</th>
        </tr>
      </thead>
      <tbody>
        {filteredTableData.map((item,index) => (
          <tr key={index}>
            <td>{index+1}</td>
            <td>{item.name}</td>
            <td>{item.createdAt}</td>
            <td className='text-success'><strong>{item.status===true ? (<b>OK</b>):(<b>NOT OK</b>)}</strong></td>
            <td>{item.role}</td>
            <td>{item.user_code}</td>
            <td>{item.balance.toFixed(2)}</td>
            <td>{item.currency}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <Bottom/>
    </div>
    </div>
    </React.StrictMode>
  
  );
}

export default Users;

