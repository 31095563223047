import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import NavBar from './NavBar';
import Panel from './Panel';
import Bottom from './Bottom';
import axios from 'axios';

const Message = () => {
    const [subject, setSubject] = useState('');
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0); // New state to keep track of unread messages count
    const [text, setText] = useState('');
    const [message, setMessage] = useState('');
    var [subscribers, setSubscribers] = useState([]);
    var [selectedSubscriber, setSelectedSubscriber] = useState([]);
    const [messages, setMessages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [messagesPerPage] = useState(5); // Number of messages per page    const navigate = useNavigate();
    const navigate = useNavigate();
    var { state } = useLocation();
    //console.log('Message superAdmin ', superAdmin);

    // Function to handle back button click
    const handleBackButton = () => {
        // Navigate to the home page when the back button is clicked
        navigate('/message');
    };

    useEffect(() => {
        // Listen for the 'popstate' event which is triggered when the back button is clicked
        window.addEventListener('popstate', handleBackButton);

        return () => {
            // Remove the event listener when the component is unmounted
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const handleSubject = (e) => {
        setSubject(e.target.value);
    };
    const handleText = (e) => {
        setText(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            var selectedSubscribers = [];
            if (selectedSubscriber === "All") {
                selectedSubscribers = subscribers.map(subscriber => subscriber.id);
                console.log('selectedSubscribers ',selectedSubscribers);
            } else {
                selectedSubscribers = [selectedSubscriber];
            }
            console.log('selectedSubscribers ', selectedSubscribers)
            console.log('selectedSubscriber ', selectedSubscriber)
            const response = await fetch('/sendemail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ subject, text, recipients: selectedSubscribers })
            });

            if (response.ok) {
                setMessage('Email sent successfully!');
            } else {
                setMessage('Failed to send email.');
            }
        } catch (error) {
            setMessage('An error occurred while sending the email.');
            console.error('Error sending email:', error);
        }
    };
    const fetchSubscribers = async () => {
        try {
            const response = await axios.post('/sendemail/fetch');
                    setSubscribers(response.data.data);
                    subscribers = response.data.data;
                    //console.log('subscribers response.data.data ', response.data.data);
                    //console.log('subscribers ', subscribers);
        } catch (error) {
            setMessage('An error occurred on fetchSubscribers.');
            console.error('Error on fetchSubscribers:', error);
        }
    };
    //fetch messages list 
    const fetchedMessages = async () => {
        try {
            const res = await axios.post('/sendemail/messages', { email: state.values.email });
            const fetchedMessages = res.data.messages;
    
            // Sort messages by date
            const sortedMessages = fetchedMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    
            // Set sorted messages in state
            setMessages(sortedMessages);
    
            // Calculate the number of unread messages
            const unreadCount = fetchedMessages.filter((message) => !message.read).length;
            setUnreadMessagesCount(unreadCount);
        } catch (error) {
            setMessage('An error occurred while fetching messages list.');
            console.error('Error fetching messages list:', error);
        }
    };

    // Call the fetchEmails function to trigger the API call
    useEffect(() => {
            // If the 'All' option exists in the subscribers list, select it by default
            fetchSubscribers();
            fetchedMessages();
    }, []);
    const handleSelect = (e) => {
        const selectedValue = e.target.value;
        selectedSubscriber=selectedValue;
        setSelectedSubscriber(selectedValue); // Set selected subject
        console.log('selectedValue ',selectedValue);
        console.log('selectedSubscriber ',selectedSubscriber);
    };
    var superAdmin = state.values.email;
    const admin = process.env.REACT_APP_SUPERADMIN;
    //console.log('superAdmin at Message',admin, superAdmin)
    if(superAdmin===admin) { superAdmin = true; } else { superAdmin = false; }
    const sortMessagesByDate = () => {
        const sortedMessages = [...messages].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        return sortedMessages.reverse();
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Get current messages
    const indexOfLastMessage = currentPage * messagesPerPage;
    const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
    const currentMessages = sortMessagesByDate().slice(indexOfFirstMessage, indexOfLastMessage);

    return (
        <React.StrictMode>
            <div className="d-flex w-100 vh-100 justify-content-center align-items-start">
                <div className="d-flex w-10 vh-100 justify-content-center align-items-start"><NavBar state={state} /></div>
                <div className="d-grid w-90 vh-1000 p-2 rounded bg-white">
                    <div className="d-flex w-100 vh-10 justify-content-center align-items-start"><Panel state={state} /></div>
                    {superAdmin ? (
                    <div className="mb-2 form-control">
                        <h2>Send message</h2>
                        <div className='mb-2 form-control'>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-2">
                            <label htmlFor="subscribers"><strong>Choose subscribers:</strong>
                                <select value={selectedSubscriber} onChange={handleSelect} className="w-100">
                                    <option value="All">All</option>
                                    {subscribers.map(subscriber => (
                                        <option key={subscriber.id} value={subscriber.id}>{subscriber.name}</option>
                                    ))}
                                </select>
                                </label>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="subject" className="w-100"><strong>Subject:</strong>
                                    <input type="subject" placeholder="Enter the subject here" value={subject} onChange={handleSubject} className="form-control rounded-0 w-100" />
                                </label>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="text" className="w-100"><strong>Text:</strong>
                                    <textarea id="text" placeholder="Enter the send message here" value={text} onChange={handleText} className="form-control rounded-0 w-100" />
                                </label>
                            </div>
                            <div className="mb-2">
                                <button type="submit" className="btn btn-success">Send</button>
                            </div>
                        </form>
                        {message && <p>{message}</p>}
                        </div>
                        <div className="mb-2">
                    <h2>Messages</h2>
                    <table className="table form-control">
                            <thead>
                                <tr>
                                    <th>CreatedAt</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentMessages.map((message) => (
                                    <tr key={message.id}>
                                        <td>{new Date(message.createdAt).toLocaleString()}</td>
                                        <td>
                                            <strong>{message.subject}</strong> <br />
                                            {message.text}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    </div>
                    
) : (
    
                    <div className="mb-2">
                    <h2>Messages</h2>
                    <table className="table form-control">
                            <thead>
                                <tr>
                                    <th>CreatedAt</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentMessages.map((message) => (
                                    <tr key={message.id}>
                                        <td>{new Date(message.createdAt).toLocaleString()}</td>
                                        <td>
                                            <strong>{message.subject}</strong> <br />
                                            {message.text}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>
)}
                        <nav>
                            <ul className="pagination">
                                {Array.from({ length: Math.ceil(messages.length / messagesPerPage) }).map((_, index) => (
                                    <li key={index} className="page-item">
                                        <button onClick={() => paginate(index + 1)} className="page-link">
                                            {index + 1}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    <Bottom />
                </div>
            </div>
        </React.StrictMode>
    );
};

export default Message;
